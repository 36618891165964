import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/Mobile.vue'),
  },
  {
    path: '/mreport',
    name: 'MReport',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MReport.vue'),
  },
  {
    path: '/mperson',
    name: 'MPerson',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MPerson.vue'),
  },
  {
    path: '/mreportlist',
    name: 'MReportList',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MReportList.vue'),
  },
  {
    path: '/mreportdetail',
    name: 'MReportDetail',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MReportDetail.vue'),
  },
  {
    path: '/mrecorddetail',
    name: 'MRecordDetail',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MRecordDetail.vue'),
  },
  {
    path: '/mrecorddetail1',
    name: 'MRecordDetail1',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MRecordDetail1.vue'),
  },
  {
    path: '/myrepair',
    name: 'MyRepair',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MyRepair.vue'),
  },
  {
    path: '/mequipment',
    name: 'MEquipment',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MEquipment.vue'),
  },
  {
    path: '/myreport',
    name: 'MyReport',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MyReport.vue'),
  },
  {
    path: '/mequipmentdetail',
    name: 'MEquipmentDetail',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MEquipmentDetail.vue'),
  },
  {
    path: '/mhistory',
    name: 'MHistory',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MHistory.vue'),
  },
  {
    path: '/maddvice',
    name: 'MAddvice',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MAddvice.vue'),
  },
  {
    path: '/mcomponent',
    name: 'MComponent',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MComponent.vue'),
  },
  {
    path: '/mchangerecord',
    name: 'MChangeRecord',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MChangeRecord.vue'),
  },
  {
    path: '/mrepairview',
    name: 'MRepairView',
    meta: { type: 'mobile' },
    component: () => import('../views/mobile/MRepairView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 重置路由
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    routes
  })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");
  if (storeMenus) {
    // 拼装动态路由
    const manageRoute = {
      path: '/',
      name: 'Manage',
      meta: { type: 'pc' },
      component: () => import('../views/Manage.vue'),
      redirect: "/home",
      children: [
        { path: 'person', name: '个人信息', component: () => import('../views/Person.vue') },
        { path: 'password', name: '修改密码', component: () => import('../views/Password.vue') },
      ]
    }
    const menus = JSON.parse(storeMenus)
    menus.forEach(item => {
      if (item.path) {  // 当且仅当path不为空的时候才去设置路由
        let itemMenu = {
          path: item.path.replace("/", ""),
          name: item.name,
          meta: { type: 'pc' },
          component: () => import('../views/' + item.pagePath + '.vue')
        }
        manageRoute.children.push(itemMenu)
      } else if (item.children && item.children.length) {  // 检查item.children是否存在且为数组
        item.children.forEach(childItem => {
          if (childItem.path) {
            let childMenu = {
              path: childItem.path.replace("/", ""),
              name: childItem.name,
              component: () => import('../views/' + childItem.pagePath + '.vue')
            }
            manageRoute.children.push(childMenu)
          }
        })
      }
    })

    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Manage')) {
      // 动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }
  }
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
  // localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  // store.commit("setPath")
  // const isMobile = localStorage.getItem('ifmobile') === '1';
  // if (!to.matched.length) {
  //   const menus = localStorage.getItem("menus")
  //   if (!menus) {
  //     next("/login")
  //   } else {
  //     next("/404")
  //   }
  // } else {
  //   next()
  // }
  document.title = 'NFO设维系统'
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")

  // 判断设备类型并重定向
  const isMobile = localStorage.getItem('ifmobile') === '1';
  if (isMobile && to.meta.type === 'pc') {
    // 移动设备访问PC端页面
    const mobileRoute = router.options.routes.find(route => route.name === 'Mobile');
    if (mobileRoute) {
      const mobilePath = mobileRoute.path;
      next({ path: mobilePath });
    } else {
      next('/404');
    }
  } else if (!isMobile && to.meta.type === 'mobile') {
    // PC设备访问移动端页面
    const pcRoute = router.options.routes.find(route => route.name === 'PC');
    if (pcRoute) {
      const pcPath = pcRoute.path;
      next({ path: pcPath });
    } else {
      next('/404');
    }
  } else {
    if (!to.matched.length) {
      const menus = localStorage.getItem("menus")
      if (!menus) {
        next("/login")
      } else {
        next("/404")
      }
    } else {
      next()
    }
  }
})

export default router
